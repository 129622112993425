import slugify from '@sindresorhus/slugify'
import { PageProps, graphql } from 'gatsby'
import { FC } from 'react'

import Breadcrumbs from '../../components/Breadcrumbs'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO/SEO'
import Survey from '../../components/Survey'
import { useQueryParam } from '../../helpers'
import { useIntlLabel } from '../../intl'
import ServiceDetailSection from './ServiceDetailSection'
import { ServiceTemplate } from './__generated__/ServiceTemplate'

interface IServiceTemplateProps extends PageProps {
    data: ServiceTemplate
}

const ServiceTemplatePage: FC<IServiceTemplateProps> = ({ data, location }) => {
    const getIntlLabel = useIntlLabel()

    const flatServiceSteps =
        data?.strapiService?.serviceSteps
            ?.sort?.((a, b) => (a?.stepOrder ?? 0) - (b?.stepOrder ?? 0))
            ?.reduce((accumVal: any[], currentVal: any) => {
                if (currentVal?.subSteps && currentVal?.subSteps?.length > 0) {
                    return [
                        ...accumVal,
                        currentVal,
                        ...currentVal.subSteps
                            .sort(
                                (a: { stepOrder: any }, b: { stepOrder: any }) =>
                                    (a?.stepOrder ?? 0) - (b?.stepOrder ?? 0),
                            )
                            .reduce((subAccum: any[], subStep: { subSteps: any[] }) => {
                                if (subStep?.subSteps && subStep?.subSteps?.length > 0) {
                                    return [
                                        ...subAccum,
                                        { ...subStep, documents: [] },
                                        ...subStep.subSteps
                                            .sort(
                                                (a, b) => (a?.stepOrder ?? 0) - (b?.stepOrder ?? 0),
                                            )
                                            .map((lastStepItm) => ({
                                                ...lastStepItm,
                                                documents: [],
                                            })),
                                    ]
                                }
                                return [...subAccum, { ...subStep, documents: [] }]
                            }, []),
                    ]
                }
                return [...accumVal, currentVal]
            }, []) ?? []

    const categorySlug = useQueryParam('category')
    const personaSlug = useQueryParam('persona')

    const category = data.strapiService?.serviceCategories?.find(
        (item: any) => item?.categorySlug == categorySlug,
    )?.categoryName

    return (
        <Layout>
            <SEO
                title={getIntlLabel(data.strapiService, 'serviceName') ?? ''}
                description={getIntlLabel(data.strapiService, 'serviceDescription')}
                pageUrl={`/services/${data?.strapiService?.serviceSlug}`}
                shareImage={data?.strapiService?.seo?.shareImage?.localFile?.publicURL}
                keywords={data?.strapiService?.seo?.keyWords}
                isArticle
            />
            <Breadcrumbs
                parent={category!!}
                current={getIntlLabel(data?.strapiService, 'serviceName') ?? ''}
                updateDate={data.strapiService?.updated_at}
            />
            <ServiceDetailSection
                serviceName={getIntlLabel(data?.strapiService, 'serviceName') ?? ''}
                serviceHint={getIntlLabel(data?.strapiService, 'serviceHint') ?? ''}
                serviceDescription={getIntlLabel(data?.strapiService, 'serviceDescription') ?? ''}
                serviceHistory={getIntlLabel(data?.strapiService, 'serviceDescription') ?? ''}
                serviceHierarchy={getIntlLabel(data?.strapiService, 'serviceHierarchy') ?? ''}
                serviceSteps={flatServiceSteps} // list des étapes détaillés
                categoryName={getIntlLabel(data?.strapiService, 'serviceName') ?? ''}
                url={location.href}
                slug={slugify(data?.strapiService?.serviceName ?? '') ?? ''}
            />
        </Layout>
    )
}

export const query = graphql`
    query ServiceTemplateQuery($id: String) {
        strapiService(id: { eq: $id }) {
            updated_at(formatString: "DD MMMM YYYY", locale: "fr")
            serviceName
            serviceName_mg
            serviceSlug
            serviceTitle
            serviceTitle_mg
            serviceSubTitle
            serviceSubTitle_mg
            serviceDescription
            serviceDescription_mg
            serviceHint
            serviceHint_mg
            serviceHistory
            serviceHistory_mg
            serviceHierarchy
            serviceHierarchy_mg
            serviceCategories {
                categoryName
                categoryName_mg
                categorySlug
            }
            application {
                applicationServeiceName
                serviceApplicationUrl
            }
            serviceSteps {
                id
                stepDescription
                stepDescription_mg
                stepName
                stepName_mg
                stepOrder
                stepType
                subSteps {
                    id
                    stepDescription
                    stepDescription_mg
                    stepName
                    stepName_mg
                    stepType
                    stepOrder
                    subSteps {
                        id
                        stepDescription
                        stepDescription_mg
                        stepName
                        stepName_mg
                        stepOrder
                        stepType
                    }
                }
                documents {
                    documentCount
                    documentName
                    documentName_mg
                }
            }
            seo {
                shareImage {
                    localFile {
                        publicURL
                    }
                }
                keyWords
            }
        }
    }
`

export default ServiceTemplatePage
