import slugify from '@sindresorhus/slugify'
import { Link } from 'gatsby'
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl'
import { FC } from 'react'

import Trusted from '../../images/Trusted.svg'
import Container from '../Container'
import {
    BreadcrumbsInner,
    BreadcrumbsInnerInfos,
    BreadcrumbsInnerLinks,
    BreadcrumbsWrapper,
} from './styles'

interface IBreadcrumbsProps {
    updateDate?: string
    parent?: string
    current: string
    searchResultCount?: number
    isService?: boolean
}

const Breadcrumbs: FC<IBreadcrumbsProps> = ({
    current,
    parent,
    updateDate,
    searchResultCount,
    isService = true,
}) => {
    const intl = useIntl()

    return (
        <BreadcrumbsWrapper>
            <Container>
                <BreadcrumbsInner>
                    <BreadcrumbsInnerLinks>
                        <Link to="/">{intl.formatMessage({ id: 'site_home_title' })}</Link>
                        {parent ? (
                            <Link
                                to={
                                    isService
                                        ? `/services/${slugify(parent!!)}`
                                        : `/${slugify(parent!!)}`
                                }
                            >
                                {parent}
                            </Link>
                        ) : (
                            ''
                        )}
                        <span>{current}</span>
                    </BreadcrumbsInnerLinks>
                    {parent ? (
                        <BreadcrumbsInnerInfos>
                            <img src={Trusted} alt="Trusted" />
                            <FormattedMessage id="label_updated_at" />: <span>{updateDate}</span>
                        </BreadcrumbsInnerInfos>
                    ) : (
                        ''
                    )}

                    {searchResultCount && !parent ? (
                        <BreadcrumbsInnerInfos>
                            {searchResultCount} <FormattedMessage id="label_results_found" />
                        </BreadcrumbsInnerInfos>
                    ) : (
                        ''
                    )}
                </BreadcrumbsInner>
            </Container>
        </BreadcrumbsWrapper>
    )
}

export default Breadcrumbs
