import styled from '@emotion/styled'

export const BreadcrumbsWrapper = styled.div((props) => ({
    [props.theme.breakpoints.down(props.theme.breakpoints.sm)]: {
        height: 0,
        visibility: 'hidden',
        marginBottom: props.theme.spacing(3),
    },
}))
export const BreadcrumbsInner = styled.div((props) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 80,

    [props.theme.breakpoints.down(props.theme.breakpoints.md)]: {
        height: '100px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
}))

export const BreadcrumbsInnerLinks = styled.div((props) => ({
    display: 'flex',
    marginLeft: props.theme.spacing(-1),
    fontSize: props.theme.spacing(2),

    '> a , > span': {
        color: props.theme.color.brand.primary[800],
        fontWeight: 700,
        textDecoration: 'none',
        margin: `0 ${props.theme.spacing(1)}`,
    },

    '> span': {
        color: 'inherit',
        fontWeight: 'normal',
    },
}))

export const BreadcrumbsInnerInfos = styled.div((props) => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: props.theme.spacing(2),

    '> span': {
        color: 'inherit',
        fontWeight: 700,
        display: 'inline-flex',
        marginLeft: props.theme.spacing(0.5),
    },

    '> img': {
        marginBottom: 0,
        marginRight: props.theme.spacing(0.5),
    },
}))
